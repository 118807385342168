import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../Button';

import styles from './RafflePrompt.scss';
import { ChatContext, events } from './ChatContext';
import Modal from './Modal';
import AvatarGroup from '../Avatar/AvatarGroup';
import Avatar from '../Avatar';
import Markdown from '~/components/Markdown';
import formatDuration from './formattingHelpers';
import { getShowingRaffleEntrants, setShowingRaffleEntrants } from '~/modules/chat';

import Wheel from './wheel.svg';
import Pix from './pix.png';
import Pix2 from './pix2.png';
import Pix3 from './pix3.png';

const cx = classNames.bind(styles);

const PixImages = [
  {
    src:Pix,
    alt:"Pix",
    className:"Pix"
  },
  {
    src:Pix2,
    alt:"Pix Celebration",
    className:"PixCelebration"
  },
  {
    src:Pix3,
    alt:"Pix Over Spinning Wheel",
    className:"PixOverSpinningWheel"
  },
];

const RafflePrompt = ({ raffle, room, className, currentUser }) => {
  const { client: socket } = useContext(ChatContext);
  const intl = useIntl();

  const dispatch = useDispatch();

  const [viewingRules, setViewingRules] = useState(false);
  const [cancellingRaffle, setCancellingRaffle] = useState(false);
  const [triggeringRaffle, setTriggeringRaffle] = useState(false);
  const [remaining, setRemaining] = useState(null);
  const [finished, setFinished] = useState(raffle.finished || false);
  const [pixVariation, setPixVariation] = useState(0);

  const isParticipating = useMemo(() => raffle.participants.indexOf(currentUser.username) !== -1, [currentUser, raffle]);
  const hasRafflePermission = currentUser.permissions.raffle;
  const isFull = raffle.maxParticipants && raffle.maxParticipants === raffle.participants?.length;
  const remainingFormatted = formatDuration(remaining);

  useEffect(() => {
    if (finished) return;

    const raffleEnd = new Date((new Date(raffle.startedAt)).getTime() + (raffle.duration * 1000));

    const updateRemaining = (newValue) => {
      setRemaining(newValue);

      if (newValue !== null && newValue <= 0) {
        setPixVariation(Math.floor(Math.random() * 3));
        setFinished(true);
      }
    };

    updateRemaining(raffleEnd.getTime() - Date.now());
    const interval = setInterval(() => {
      updateRemaining(raffleEnd.getTime() - Date.now());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [raffle, finished]);

  const joinRaffle = useCallback(() => {
    socket.emit(events.JOIN_RAFFLE, {
      roomId: room.id,
    });
  }, [socket, room.id]);

  const cancelRaffle = useCallback(() => {
    socket.emit(events.CANCEL_RAFFLE, {
      roomId: room.id,
    });
  }, [room.id, socket]);

  const triggerRaffle = useCallback(() => {
    setPixVariation(Math.floor(Math.random() * 3));
    socket.emit(events.TRIGGER_RAFFLE, {
      roomId: room.id,
    });
  }, [socket, room.id]);

  const Participate = () => {
    if (isFull) {
      return (
        <FormattedMessage
          id="RafflePrompt_Full"
          defaultMessage="Full"
        />
      );
    }

    if (isParticipating) {
      return (
        <FormattedMessage
          id="RafflePrompt_Joined"
          defaultMessage="Joined"
        />
      );
    }

    return (
      <FormattedMessage
        id="RafflePrompt_Join"
        defaultMessage="Join raffle"
      />
    );
};

  const cancelTooltipText = finished ? (
    {
      id: 'RafflePrompt_EndTooltip',
      defaultMessage: 'End raffle',
    }
  ) : (
    {
      id: 'RafflePrompt_CancelTooltip',
      defaultMessage: 'Cancel raffle',
    }
  );

  if (finished && !raffle.finished) {
    return (
      <div className={cx('RafflePrompt', 'RafflePrompt--Selecting')}>
        <img className={cx('RafflePrompt__SpinningWheel')} src={Wheel} alt="Spinning wheel" />
        <img className={cx(`RafflePrompt__${PixImages[pixVariation].className}`)} src={PixImages[pixVariation].src} alt={PixImages[pixVariation].alt} />
      </div>
    );
  }

  return (
    <div className={cx('RafflePrompt', className)}>
      {
        hasRafflePermission && (
          <Button 
            className={cx('RafflePrompt__Cancel')}
            color="transparent"
            onClick={() => setCancellingRaffle(true)}
            title={intl.formatMessage(cancelTooltipText)}
          >
            <span role="img" className="ion-close-circled" />
          </Button>
        )
      }

      {
        cancellingRaffle && (
          createPortal((
            <Modal title={<FormattedMessage id="RafflePrompt_CancelTitle" defaultMessage="Cancel raffle" />} close={() => setCancellingRaffle(false)}>
              <div style={{ textAlign: 'center' }}>
                <FormattedMessage id="RafflePrompt_CancelText" defaultMessage="Cancel raffle?" />

                <br />

                <Button
                  color="red"
                  onClick={() => {
                    setCancellingRaffle(false);
                    cancelRaffle();
                  }}
                >
                  <FormattedMessage id="RafflePrompt_CancelConfirm" defaultMessage="Cancel raffle" />
                </Button>
                <Button onClick={() => setCancellingRaffle(false)}>
                  <FormattedMessage id="RafflePrompt_CancelExit" defaultMessage="Close" />
                </Button>
              </div>
            </Modal>
          ), document.getElementById('Homebrew_ModalPortal'))
        )
      }

      {
        viewingRules && raffle.rules && (
          createPortal((
            <Modal title={<FormattedMessage id="RafflePrompt_RulesModal" defaultMessage="Raffle rules" />} close={() => setViewingRules(false)}>
              <Markdown>
                {raffle.rules}
              </Markdown>
            </Modal>
          ),  document.getElementById('Homebrew_ModalPortal'))
        )
      }

      {
        triggeringRaffle && (
          createPortal(
            (
              <Modal title={<FormattedMessage id="RafflePrompt_TriggeringRaffle" defaultMessage="Trigger raffle" />} close={() => setTriggeringRaffle(false)}>
                <div style={{ textAlign: 'center' }}>
                  <FormattedMessage id="RafflePrompt_TriggerText" defaultMessage="Trigger raffle?" />

                  <br />

                  <Button color="red" onClick={() => setTriggeringRaffle(false)}>
                    <FormattedMessage id="RafflePrompt_CancelExit" defaultMessage="Close" />
                  </Button>
                  <Button
                    onClick={() => {
                      setTriggeringRaffle(false);
                      triggerRaffle();
                    }}
                  >
                    <FormattedMessage id="RafflePrompt_TriggerConfirm" defaultMessage="Trigger raffle" />
                  </Button>
                </div>
              </Modal>
            ), document.getElementById('Homebrew_ModalPortal'))
        )
      }

      <div className={cx('RafflePrompt__Icon')}>
        <span aria-label="Raffle Icon" role="img">🎟️</span>
      </div>

      <div className={cx('RafflePrompt__Description')}>
        <div className={cx('RafflePrompt__Title')}>
          {
            raffle.prompt ? (
              raffle.prompt
            ) : (
              <FormattedMessage
                id="RafflePrompt_Started" 
                defaultMessage="{username} started a raffle!"
                values={{
                  username: raffle.startedBy
                }}
              />
            )
          }
        </div>

        {
          !finished && (
            <Button color="transparent" onClick={() => dispatch(setShowingRaffleEntrants(true))} className={cx('RafflePrompt__Participants')}>
              <FormattedMessage
                id="RafflePrompt_Participants"
                defaultMessage="{participants, plural, =0 {no participants} one {# participant} other {# participants}}"
                values={{
                  participants: raffle.participants.length,
                }}
              />
              &nbsp;
              {
                raffle.maxParticipants > 0 && (
                  <FormattedMessage
                    id="RafflePrompt_MaxParticipants"
                    defaultMessage="({maxParticipants} max.)"
                    values={{
                      maxParticipants: raffle.maxParticipants
                    }}
                  />
                )
              }
              &nbsp;
              <AvatarGroup className={cx('RafflePrompt__AvatarGroup')} display={3}>
                {
                  raffle.participants.map(username => <Avatar key={username} username={username} />)
                }
              </AvatarGroup>
            </Button>
          )
        }

        {
          raffle.winners && raffle.finished && (
            <div>
              <FormattedMessage
                id="RafflePrompt_Winners"
                defaultMessage="Raffle finished, {winnerCount, plural, =0 {no winners} one {winner} other {winners}}: {winners}"
                values={{
                  winners: raffle.winners.join(', '),
                  winnerCount: raffle.winners.length,
                }}
              />
            </div>
          )
        }

        <div>
          {
            hasRafflePermission && (
              <Button size="small" onClick={() => setTriggeringRaffle(true)}>
                <FormattedMessage
                  id="RafflePrompt_Trigger"
                  defaultMessage="Trigger raffle"
                />
              </Button>
            )
          }
          {
            raffle.rules && (
              <Button size="small" onClick={() => setViewingRules(true)}>
                <span role="img" className="ion-ios-paper-outline" />
                &nbsp;
                <FormattedMessage
                  id="RafflePrompt_ViewRules"
                  defaultMessage="View rules"
                />
              </Button>
            )
          }
        </div>
      </div>


      <div className={cx('RafflePrompt__Action')}>
        <Button size="small" disabled={finished || isParticipating || isFull} onClick={joinRaffle}>
          {
            finished ? (
              <FormattedMessage
                id="RafflePrompt_Ended"
                defaultMessage="Raffle ended"
              />
            ) : (
              <Participate />
            )
          }
        </Button>
        {
          !finished && (
            <div>
              <span role="img" className="ion-android-stopwatch" />
              &nbsp;
              {remainingFormatted}
            </div>
          )
        }

      </div>

      <div className={cx('RafflePrompt__MaxWinners')}>
        <FormattedMessage
          id="RafflePrompt_MaxWinners"
          defaultMessage="{maxWinners, plural, =0 {# winners} one {# winner} other {# winners}}"
          values={{
            maxWinners: raffle.maxWinners,
          }}
        />
      </div>
    </div>
  );
};

export default RafflePrompt;
