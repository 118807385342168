import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

import FollowButton from '~/components/Button/Follow';

import styles from './UserCard.scss';

const cx = classnames.bind(styles);

/**
 * Displays a user's general info with link
 */
class UserCard extends React.Component {
  static propTypes = {
    /** Where to link the user's username */
    to: PropTypes.string,
    /** user */
    user: PropTypes.shape({
      username: PropTypes.string,
    }),
  }

  render() {
    const {
      user: { avatar },
      user,
      followButton,
      className,
      to,
    } = this.props;

    return (
      <div className={cx('UserCard', className)}>
        <img className={styles.UserCard__Image} src={`${user.avatar.url}`} alt={`${user.username}'s avatar`} />
        <Link className={styles.UserCard__Username} to={to || `/watch/${user.username}`}>{user.username}</Link>
        <FollowButton username={user.username} className={styles.UserCard__Follow} />
      </div>
    );
  }
}

export default UserCard;
