import React, {
  useRef, useEffect, useState,
  useCallback, useMemo,
} from 'react';
import * as emoji from '@piczel/emoji/frontend';
import { getCategories, getEmote, tones } from '@piczel/emoji';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useInView } from 'react-intersection-observer';

import {
  getEmojiTone, setEmojiTone, getGlobalEmoticons,
  loadGlobalEmoticons, loadEmoticonCategories, getEmoticonCategories, getUsedEmoticons, addUsedEmoticon,
  getNsfwEmotesHidden, validateEmoticonSet,
} from '~/modules/chat';
import PiczelCategoryIcon from './PiczelCategoryIcon.png';

import styles from './EmoticonPicker.scss';
import Config from '~/config';

const c = classNames.bind(styles);

const emojiCategories = getCategories();

const EmoticonSidebar = ({ categories }) => {
  const scrollIntoView = useCallback((categoryName) => {
    const el = document.getElementById(`emoticon_category_${categoryName}`);

    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className={styles.EmoticonPane__Sidebar}>
      {
        categories.map(category => (
          <button title={category.title} key={category.name} onClick={() => scrollIntoView(category.name)} className={styles.EmoticonPane__SidebarOption} type="button">
            <img src={category.icon} alt={category.title} />
          </button>
        ))
      }
    </div>
  );
};

EmoticonSidebar.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
};

const EmoticonList = ({
  title, emoticons, id, onSelect,
}) => {
  const {ref, inView} = useInView();
  return (

    <div ref={ref} id={`emoticon_category_${id}`} className={styles.EmoticonPane__List}>
      <div className={styles.EmoticonPane__ListTitle}>
        { title }
      </div>

      <div className={styles.EmoticonPane__ListContent}>
        {
          emoticons.map(emote => (
            <button title={emote.plain} type="button" onClick={() => onSelect(emote)} key={emote.id} className={styles.Emoticon}>
              {
                inView && <img loading="lazy" src={emote.image} alt={emote.plain} />
              }
            </button>
          ))
        }
      </div>
    </div>
  );
};
EmoticonList.propTypes = {
  // Title displayed above the emoticons
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  emoticons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    plain: PropTypes.string,
  })).isRequired,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const TonePicker = ({ currentTone }) => {
  const clapEmojiId = 'u1f44f';
  const clapEmoji = getEmote(clapEmojiId, currentTone);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef(null);

  useEffect(() => {
    function clickAwayListener(e) {
      const picker = ref.current;

      if (picker) {
        if (!open || picker.contains(e.target) || e.target === picker) return;

        setOpen(false);
      }
    }

    window.addEventListener('click', clickAwayListener);

    return () => window.removeEventListener('click', clickAwayListener);
  }, [open]);

  return (
    <div ref={ref} className={styles.EmoticonPane__TonePicker}>
      <button onClick={() => setOpen(true)} className={styles.EmoticonPane__TonePickerTone} type="button">
        <img src={emoji[clapEmoji.id]} alt={clapEmoji.plain} />
      </button>

      {
        open && (
          <div className={styles.EmoticonPane__TonePickerList}>
            {
              Object.values(tones).map((toneId) => {
                const option = getEmote(clapEmojiId, toneId);

                return (
                  <button onClick={() => dispatch(setEmojiTone(toneId))} key={toneId} className={styles.EmoticonPane__TonePickerTone} type="button">
                    <img src={emoji[option.id]} alt={option.plain} />
                  </button>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};

TonePicker.propTypes = {
  currentTone: PropTypes.string.isRequired,
};

/**
 * Global emotes
 */
const PiczelCategory = ({ onSelect, filter }) => {
  const globalEmoticons = useSelector(getGlobalEmoticons);
  const dispatch = useDispatch();

  const emoticons = useMemo(() => globalEmoticons.filter(emote => filter.test(emote.plain)), [filter, globalEmoticons]);

  useEffect(() => {
    dispatch(loadGlobalEmoticons());
  }, [dispatch]);

  return <EmoticonList title="Piczel" emoticons={emoticons} onSelect={onSelect} id="Piczel" />;
};

PiczelCategory.propTypes = {
  onSelect: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(RegExp).isRequired,
};

const FrequentlyUsed = ({ onSelect, filter }) => {
  const usedEmoticons = useSelector(getUsedEmoticons);

  const emoticons = useMemo(() => usedEmoticons.filter(emote => filter.test(emote.plain)), [filter, usedEmoticons]);

  if (emoticons.length === 0) {
    return null;
  }
  
  return <EmoticonList onSelect={onSelect} title="Frequently Used" id="FrequentlyUsed" emoticons={emoticons} />;
};


const EmoticonPane = ({
  open, onClose, setMessage, roomName,
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const emojiTone = useSelector(getEmojiTone);
  const [filter, setFilter] = useState('');
  const filterRegex = useMemo(() => new RegExp(filter.length > 2 ? filter : ''), [filter]);

  const categories = useMemo(() => emojiCategories.map(cat => ({
    ...cat,
    icon: emoji[cat.icon],
  })), []);
  const roomCategories = useSelector(getEmoticonCategories);
  const nsfwEmotesHidden = useSelector(getNsfwEmotesHidden);

  useEffect(() => {
    function clickAwayListener(e) {
      const currentRef = ref.current;

      if (currentRef) {
        if (e.target !== currentRef && !currentRef.contains(e.target)) {
          return onClose();
        }
      }

      return false;
    }

    if (open) {
      document.addEventListener('click', clickAwayListener);
    }

    return () => document.removeEventListener('click', clickAwayListener);
  }, [onClose, open]);

  useEffect(() => {
    if ('usedEmoticons' in localStorage) {
      const emoticonSet = JSON.parse(localStorage.getItem('usedEmoticons') || '[]');

      dispatch(validateEmoticonSet(emoticonSet));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadEmoticonCategories(roomName));
  }, [roomName, dispatch]);

  const onSelect = useCallback((emote) => {
    setMessage(message => `${message} ${emote.plain}`);
    onClose();
    dispatch(addUsedEmoticon(emote));
  }, [setMessage, dispatch, onClose]);

  const categoryEmojis = useMemo(() => categories.reduce((obj, category) => {
    if (category.name in obj === false) {
      obj[category.name] = category.emoji.map(emoteId => ({
        ...getEmote(emoteId),
        image: emoji[emoteId],
      })).filter(emote => (emote.diversity === emojiTone || (!emote.diversity && !emote.has_diversity)));
    }
    return obj;
  }, {}), [categories, emojiTone]);

  const emoticonList = useMemo(() => categories.map(category => (
    <EmoticonList
      id={category.name}
      key={category.name}
      title={category.title}
      emoticons={categoryEmojis[category.name].filter(emote => filterRegex.test(emote.plain))}
      onSelect={onSelect}
    />
  )), [categories, categoryEmojis, onSelect, filterRegex]);

  const onChange = useCallback(e => setFilter(e.target.value), [setFilter]);

  return (
    <div ref={ref} className={c('EmoticonPane', { 'EmoticonPane--Open': open })}>
      <EmoticonSidebar
        categories={[
          {
            name: 'Piczel',
            title: 'Piczel',
            icon: PiczelCategoryIcon,
          },
          {
            name: 'FrequentlyUsed',
            title: 'Frequently Used',
            icon: emoji.u1f552,
          },
          ...Object.keys(roomCategories).map(username => ({
            name: username,
            title: username,
            icon: `${Config.api}/avatars/${username}`,
          })),
          ...categories,
        ]}
      />
      <div className={styles.EmoticonPane__Content}>
        <div className={styles.EmoticonPane__Toolbar}>
          <div className={styles.EmoticonPane__Filter}>
            <label htmlFor="EmoticonPane__Filter--Input" className={styles.EmoticonPane__FilterLabel}>
              Search
              <input id="EmoticonPane__Filter--Input" onChange={onChange} value={filter} type="text" placeholder="Search" className={styles.EmoticonPane__FilterInput} />
            </label>
          </div>

          <TonePicker currentTone={emojiTone} />
        </div>
        <div className={styles.EmoticonPane__ListContainer}>
          <FrequentlyUsed filter={filterRegex} onSelect={onSelect} />
          <PiczelCategory filter={filterRegex} onSelect={onSelect} />
          {
            nsfwEmotesHidden && (
              <div className={styles.EmoticonPane__NsfwHiddenWarning}>
                <FormattedMessage
                  id="EmoticonPane_NsfwHidden"
                  defaultMessage="Some emotes may be hidden by your {contentFilterLink}"
                  values={{
                    contentFilterLink: (
                      <Link to="/account/content/visibility">
                        <FormattedMessage id="EmoticonPane_ContentFilterLink" defaultMessage="content filter settings" />
                      </Link>
                    ),
                  }}
                />
              </div>
            )
          }
          {
            Object.keys(roomCategories).map(username => (
              <EmoticonList
                id={username}
                key={username}
                title={username}
                onSelect={onSelect}
                emoticons={roomCategories[username].filter(emote => filterRegex.test(emote.plain))}
              />
            ))
          }
          {
            emoticonList
          }
        </div>
      </div>
    </div>
  );
};

EmoticonPane.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
};

export default React.memo(EmoticonPane);
