/* node_modules */
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactMarkdownWithHtml } from "~/components/Markdown";

/* local imports */
import config from '~/config';

import FormattedMessage from '~/components/common/FormattedMessage';
import Button, { FollowButton } from '~/components/Button';

/* style imports */
import styles from './UserCard.scss';

/**
 * Displays a user's general info
 */
class UserCard extends React.Component {
  streamLink() {
    const { user } = this.props;

    return (
      <Link to={`/watch/${user.username}`}>
        <button>{`${user.username}'s Stream`} {(user.stream && user.stream.live) && '[Live]'}</button>
      </Link>
    );
  }

  uploadLink() {
    return (
      <Button to="/gallery/upload">
        <FormattedMessage
          id="Gallery_UploadMedia"
          defaultMessage="Upload media"
        />
      </Button>
    );
  }

  render() {
    const {
      isSignedIn,
      currentUser,
      user,
    } = this.props;
    console.log(user.gallery)
    if (!user) {
      return (
        <p>
          <FormattedMessage
            id="GalleryPage_Loading"
            defaultMessage="Loading..."
          />
        </p>
      );
    }

    return (
      <div className={styles.UserCard}>
        <img
          className={styles.UserCard__Photo}
          src={`${config.api}/avatars/${user.username}`}
        />
        <span className={styles.UserCard__Username}>{user.username}</span>
        <div className={styles.UserCard__FollowerCount}>
          <b>{user.follower_count}</b>
          <span>
            <FormattedMessage
              id="SocialFollowers_Subheading"
              defaultMessage="Followers"
            />
          </span>
        </div>
        {isSignedIn && user.stream && <FollowButton username={user.username} />}
        {isSignedIn && currentUser.username === user.username
          ? this.uploadLink()
          : this.streamLink()}
        <div
          className={styles.UserCard__Description}
        >
          <ReactMarkdownWithHtml children={user.gallery.profile_description} />
        </div>
      </div>
    );
  }
}

export default UserCard;
