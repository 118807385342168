/* node_modules */
import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Route, Switch, Redirect, NavLink,
} from 'react-router-dom';
import classnames from 'classnames/bind';
import loadable from '@loadable/component';

/* local imports */
import FormattedMessage from '~/components/common/FormattedMessage';

/* style imports */
import styles from './Home.scss';
import ContestAd from '~/components/ContestAd';
import { HomepageBanner, HomepageBannerDescription, HomepageBannerImage, HomepageBannerTitle } from '~/components/HomepageBanner';
import Markdown from '~/components/Markdown';

const cx = classnames.bind(styles);

/* routes */
const Streams = loadable(() => import('./Streams'));
const Feed = loadable(() => import('./Feed'));
const Gallery = loadable(() => import('./Gallery'));

const Home = () => {
  const { isSignedIn } = useSelector(state => state.currentUser);
  const activeBanner = useSelector(state => state.holidayBanners.activeBanner);

  return (
    <div>
      {
        activeBanner ? (
          <HomepageBanner titleOnly={!activeBanner.description}>
            <HomepageBannerTitle>
              {activeBanner.title}
            </HomepageBannerTitle>

            {
              activeBanner.description && (
                <HomepageBannerDescription>
                  <Markdown>
                    {activeBanner.description}
                  </Markdown>
                </HomepageBannerDescription>
              )
            }

            {
              activeBanner.image && (
                <HomepageBannerImage>
                  <img src={activeBanner.image.url} />
                </HomepageBannerImage>
              )
            }
          </HomepageBanner>
        ) : (
          <ContestAd />
        )
      }

      <nav className={styles.Home__SectionPicker}>
        <NavLink
          to="/streams"
          className={styles.Home__SectionPickerOption}
          activeClassName={styles.Home__SectionPickerOptionActive}
        >
          <i className={cx('ion-ios-film', styles.Home__SectionPickerIcon)} />
          {' '}
          <span className={styles.Home__SectionName}>
            <FormattedMessage id="Home_Streams" defaultMessage="Streams" />
          </span>
        </NavLink>
        {isSignedIn && (
          <NavLink
            to="/feed"
            className={styles.Home__SectionPickerOption}
            activeClassName={styles.Home__SectionPickerOptionActive}
          >
            <i className={cx('ion-fireball', styles.Home__SectionPickerIcon)} />
            {' '}
            <span className={styles.Home__SectionName}>
              <FormattedMessage id="Home_Feed" defaultMessage="Feed" />
            </span>
          </NavLink>
        )}
        <NavLink
          to="/gallery"
          className={styles.Home__SectionPickerOption}
          activeClassName={styles.Home__SectionPickerOptionActive}
        >
          <i className={cx('ion-images', styles.Home__SectionPickerIcon)} />
          {' '}
          <span className={styles.Home__SectionName}>
            <FormattedMessage id="Home_Gallery" defaultMessage="Gallery" />
          </span>
        </NavLink>
      </nav>

      <Switch>
        <Route exact path="/" component={() => <Redirect to={isSignedIn ? '/feed' : '/streams'} />} />
        <Route path="/streams" component={Streams} />
        <Route path="/feed" component={Feed} />
        <Route path="/gallery" component={Gallery} />
      </Switch>
    </div>
  );
};

export default Home;
