/* node_modules */
import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';

/* local imports */
import config from '~/config';
import { deleteImage } from '~/modules/gallery';
import { visibilityFilter } from '~/helpers/data';

import FormattedMessage from '~/components/common/FormattedMessage';
import Loading from '~/components/Loading';

import ImagePreview from '../ImagePreview';

/* style imports */
import styles from './ImageSet.scss';

const cx = classnames.bind(styles);

const ImageSet = ({
  images: items,
  currentUser,
  adultToggleState,
  tagCheck,
  pass,
  noUsernames,
  isLoading,
}) => {
  const dispatch = useDispatch();

  const confirmDeletion = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (window.confirm('Delete this image?')) {
      dispatch(deleteImage(id));
    }

    return false;
  }

  if (isLoading && !images.length > 0) return <Loading message="Loading images..." />;

  const images = visibilityFilter(items, adultToggleState);

  const imagesHidden = images.length !== items.length;

  return (
    <>
      {
        imagesHidden && (
          <div className={styles.ImageSet__Warning}>
            <span role="img" className="ion-android-warning" />
            {' '}
            Some images hidden by your content filter setting
          </div>
        )
      }
      <div
        className={styles.ImageSet}
      >
        {
          images.length > 0
            ? images.map(image => <ImagePreview {...image} key={`img:${image.id}`} deleteImage={currentUser && (currentUser.role === 'admin' || image.user.username === currentUser.username) ? confirmDeletion : false} isBlacklisted={false} noUsernames={noUsernames} />)
            : <FormattedMessage id="ImageSet_Empty" defaultMessage="There doesn't seem to be anything here." />
        }
      </div>
    </>
  );
};

export default ImageSet;
