/* node_modules */
import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

/* local imports */
import GalleryUser from './User';
import GalleryUpload from './Image/Upload';

/**
 * Gallery routing component
 */
const Gallery = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/upload`} component={GalleryUpload} />
    <Route path={`${match.path}/:username`} component={GalleryUser} />
  </Switch>
);

Gallery.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default Gallery;
