/* node_modules */
import React from 'react';
import { matchPath } from 'react-router';
import {
  NavLink, Link, Switch, Route,
} from 'react-router-dom';
import { connect } from 'react-redux';

/* local imports */
import {
  fetchUser,
  getUserByName,
  FETCHING_USER,
} from '~/modules/user';

import {
  fetchImages,
  fetchFolders,
  deleteFolder,
  updateFolder,
  getImagesByUser,
  getImagesByFolder,
  getImagesBySection,
  FETCHING_IMAGES,
  getFolders,
} from '~/modules/gallery';

import { addFlash } from '~/modules/flashes';
import { getVisibilitySettings } from '~/modules/contentVisibility';
import { isLoading } from '~/modules/loading';

import FormattedMessage from '~/components/common/FormattedMessage';
import ImageSet from '~/components/Gallery/ImageSet';
import Loading from '~/components/Loading';
import AdultToggle from '~/components/AdultToggle';
import PasswordPrompt from '../PasswordPrompt';
import Button from '~/components/Button';

import UserCard from './UserCard';
import FolderSet from './FolderSet';

/* style imports */
import styles from './Page.scss';

/**
 * Displays a single user's gallery
 */
class GalleryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: null,
      loadingMore: false,
    };

    this.getRequestId = this.getRequestId.bind(this);
    this.loadData = this.loadData.bind(this);
    this.scrollListener = this.scrollListener.bind(this);
  }

  componentDidMount() {
    const {
      location: { pathname },
      currentUser,
    } = this.props;

    this.loadData(pathname);

    window.addEventListener('touchmove', this.scrollListener);
    window.addEventListener('scroll', this.scrollListener);
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname: prevPathname } } = prevProps;
    const { location: { pathname } } = this.props;

    // If section changes, load new images
    if (prevPathname !== pathname) {
      this.loadData(pathname);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.scrollListener);
    window.removeEventListener('scroll', this.scrollListener);
  }

  getRequestId(pathname) {
    const {
      match: { params: { username } },
    } = this.props;

    const splitPath = pathname.split('/');
    const loadFavorites = splitPath[splitPath.length - 1] === 'favorites';
    const folderId = !loadFavorites && Number(splitPath[splitPath.length - 1]);

    let requestId = `GALLERY_${username}`;

    if (loadFavorites) requestId += '_FAVORITES';
    if (folderId) requestId += `_FOLDER_${folderId}`;

    return requestId;
  }

  loadData(pathname, opts = {}) {
    const {
      dispatch,
      match: { params: { username } },
      user,
      isLoading,
      location,
    } = this.props;

    const {
      currentPassword: password,
    } = this.state;

    if (!user || user.username !== username || !user.gallery) {
      dispatch(fetchUser(username));
    }

    const splitPath = pathname.split('/');
    const loadFavorites = splitPath[splitPath.length - 1] === 'favorites';
    const folderId = !loadFavorites && Number(splitPath[splitPath.length - 1]);

    if (isLoading[this.getRequestId(location.pathname)]) {
      return;
    }

    return dispatch(fetchImages({
      username,
      favorites: loadFavorites,
      folderId,
      keepOld: opts.keepOld || false,
      password,
      requestId: this.getRequestId(pathname),
      fromId: opts.fromId,
    })).then((res) => {
      if (res.folder && password) this.setState({ currentPassword: null });
    });
  }

  scrollListener() {    
    if (window.innerHeight + document.documentElement.scrollTop
      !== document.documentElement.offsetHeight) return;

    this.loadMore();
  }

  async loadMore() {
    const {
      location: { pathname },
      fromId,
      isLoading,
    } = this.props;

    const curFromId = fromId[this.getRequestId(pathname)];

    if (isLoading[this.getRequestId(location.pathname)] || (curFromId && curFromId === -1)) return;
    
    this.setState({
      loadingMore: true,
    });

    await Promise.resolve(this.loadData(pathname, { fromId: curFromId, keepOld: true }));

    this.setState({
      loadingMore: false,
    });
  }

  render() {
    const {
      dispatch,
      match,
      isSignedIn,
      currentUser,
      user,
      gallery,
      images: {
        byUser,
        byFolder,
        bySection,
      },
      folders,
      isLoading,
      location: { pathname },
      adultToggleState,
      fromId
    } = this.props;

    const {
      loadingMore
    } = this.state;

    if (isLoading.user || isLoading.gallery || !user) return <Loading />;

    return (
      <div className={styles.GalleryPage}>
        {(isLoading.user || !user || !user.gallery) ? <Loading /> : (
          <UserCard {...{
            isSignedIn, currentUser, user,
          }}
          />
        )}

        <AdultToggle />

        <div className={styles.GalleryPage__Navigation}>
          <NavLink exact to={`${match.url}`} activeClassName={styles.GalleryPage__NavigationLinkActive}>
            <FormattedMessage
              id="ImageGallery_Images"
              defaultMessage="Images"
            />
          </NavLink>
          <span>—</span>
          <NavLink to={`${match.url}/favorites`} activeClassName={styles.GalleryPage__NavigationLinkActive}>
            <FormattedMessage
              id="ImageGallery_Favorites"
              defaultMessage="Favorites"
            />
          </NavLink>
        </div>
        {!pathname.endsWith('favorites')
          && (
            <FolderSet
              {...{ user, images: byUser }}
              showActions={currentUser && (currentUser.role === 'admin' || user.username === currentUser.username)}
              actions={{
                deleteFolder: id => dispatch(deleteFolder(id)),
                protectFolder: (id, password) => dispatch(updateFolder(id, { password })),
              }}
            />
          )}
        <Switch>
          <Route
            exact
            path={`${match.path}`}
            render={() => (
              <ImageSet
                images={byUser}
                currentUser={currentUser}
                adultToggleState={adultToggleState}
                isLoading={isLoading.gallery}
                noUsernames
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/favorites`}
            render={() => (
              <ImageSet
                images={bySection(gallery.sections[`FAVORITES_${user.username}`])}
                currentUser={currentUser}
                adultToggleState={adultToggleState}
                isLoading={isLoading.gallery}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/:folderId`}
            render={(props) => {
              const folderId = parseInt(props.match.params.folderId);
              const folder = folders.find(item => item.id === folderId);

              if (!folder) return <Loading />;

              if (folder.password_protected && !this.state.currentPassword) {
                return (
                  <PasswordPrompt
                    handleSubmit={(values) => {
                      this.setState({ currentPassword: values.password });
                      this.loadData(pathname);
                    }}
                  />
                );
              }

              return (
                <ImageSet
                  images={byFolder(folderId)}
                  currentUser={currentUser}
                  adultToggleState={adultToggleState}
                  isLoading={isLoading.gallery}
                  noUsernames
                />
              );
            }}
          />
        </Switch>
        
        <div style={{ textAlign: 'center' }}>
          {
            !loadingMore && fromId[this.getRequestId(pathname)] && fromId[this.getRequestId(pathname)] !== -1 && (
              <Button onClick={() => this.loadMore()}>
                <FormattedMessage id="Gallery_LoadMore" defaultMessage="Load more" />
              </Button>
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: {
    user: isLoading(state.loading, FETCHING_USER),
    gallery: isLoading(state.loading, FETCHING_IMAGES),
    ...state.loading,
  },
  user: getUserByName(state, ownProps.match.params.username),
  gallery: state.gallery,
  images: {
    byUser: getImagesByUser(state, ownProps.match.params.username),
    bySection: section => getImagesBySection(state, section),
    byFolder: folderId => getImagesByFolder(state, folderId),
  },
  folders: getFolders(state),
  isSignedIn: state.currentUser.isSignedIn,
  currentUser: state.currentUser.data,
  adultToggleState: getVisibilitySettings(state),
  fromId: state.fromId,
});

export default connect(mapStateToProps)(GalleryPage);
