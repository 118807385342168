// @ts-check
import React from 'react';
import { connect, batch } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import memoize from 'memoize-one';
import classNames from 'classnames/bind';

import {
  getChatWidth, isDescriptionHidden, setPlayingStreams, setStreamFocus, startPlayingRecording, getPlayingRecordings, getPlaybackKeys, isLargeMulti,
} from '~/modules/streams';
import StreamPlayer from '../StreamPlayer';

import styles from './index.scss';

import StreamPoster from '../StreamPoster';
import { getStreamLayout, Frame } from '~/helpers/stream_layout';
import TitleBar from './TitleBar';

import ContentBlur from '../ContentBlur';
import { ChatContext, events } from '../Chat/ChatContext';
import { STREAMSHOTS } from '../StreamPlayer/playerTechs';

const cx = classNames.bind(styles);

const memoStreamLayout = memoize(getStreamLayout, ([frameA, streamsA, focusA], [frameB, streamsB, focusB]) => {
  return frameA.w === frameB.w && frameA.h === frameB.h && streamsA === streamsB && focusB === focusA;
});

const streamsWithRoomId = (streams, rooms) => streams.map((stream) => {
  const roomId = Object.values(rooms).find(
    room => stream.username === room.name || stream.parent_streamer === room.name,
  )?.id;
  return { ...stream, roomId };
});

export const visibleStreams = memoize((streams, location, focused, rooms, recordings = {}) => {
  const hasLiveStreams = streams.some(stream => stream.live);
  const currentPath = typeof window === 'undefined' ? location.pathname.toLowerCase() : window.location.pathname.toLowerCase();
  const streamsPlaying = streams.filter((stream) => {
    const isStreamInUrl = currentPath.includes(stream.username.toLowerCase());
    const isStreamPlayingRecording = recordings[stream.username];

    return stream.live || isStreamInUrl || isStreamPlayingRecording;
  });

  const visibleStreams = hasLiveStreams ? streamsPlaying : streams;
  
  if (focused) {
    return Array.from(visibleStreams).sort((streamA, streamB) => {
      if (streamA.username === streamB.username) return 0;
      if (streamA.username === focused) return -1;
      if (streamB.username === focused) return 1;
      return 0;
    });
  }

  return streamsWithRoomId(visibleStreams, rooms);
});

const autoplayStreamsThreshold = 4;

class StreamContainer extends React.Component {
  static propTypes = {
    playbackKeys: propTypes.objectOf(propTypes.string).isRequired,
    descriptionHidden: propTypes.bool.isRequired,
    chatWidth: propTypes.string,
    streams: propTypes.arrayOf(propTypes.shape({
      live: propTypes.bool,
      id: propTypes.number,
    })).isRequired,
    toggleStreamFocus: propTypes.func.isRequired,
    focused: propTypes.string,
    removeStream: propTypes.func.isRequired,
    stopRecording: propTypes.func.isRequired,
    recordings: propTypes.objectOf(propTypes.number).isRequired,
    defaultPlayer: propTypes.string.isRequired,
    defaultVjsTech: propTypes.string.isRequired,
    performantMultis: propTypes.bool.isRequired,
    showBitrates: propTypes.bool,
    viewLayout: propTypes.string.isRequired,
  };

  static defaultProps = {
    chatWidth: null,
    focused: null,
    showBitrates: false,
  };

  static contextType = ChatContext;

  constructor(props) {
    super(props);

    this.state = {
      useFrames: null,
      width: null,
      height: null,
    };

    this.container = React.createRef();
  }

  componentDidMount() {
    // if (!isMobile()) {
    //   this.updateContainerSize();
    // }

    if ('ResizeObserver' in window) {
      const observer = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (!entry) return;
        requestAnimationFrame(() => {
          this.setState({
            useFrames: this.allowFrames(),
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        });
      });

      if (this.container.current) {
        observer.observe(this.container.current);
        this.observer = observer;
      }
    } else {
      this.containerListener = () => this.updateContainerSize();
      window.addEventListener('resize', this.containerListener);
    }
  }

  componentWillUnmount() {
    const { toggleStreamFocus } = this.props;

    if (this.containerListener) {
      window.removeEventListener('resize', this.containerListener);
    }

    if (this.observer) {
      this.observer.disconnect();
    }

    toggleStreamFocus(null);
  }

  updateContainerSize() {
    const { height, width } = this.containerSize();

    this.setState({
      useFrames: this.allowFrames(),
      height,
      width,
    });
  }

  allowFrames() {
    const { viewLayout } = this.props;
    return ((typeof window !== 'undefined' && window.innerWidth >= 700) && viewLayout !== 'Mobile' && !(window.innerWidth <= 950 && viewLayout === 'Auto'));
  }

  containerSize() {
    const rect = this.container.current.getBoundingClientRect();

    return rect;
  }

  removeStream(username) {
    const { removeStream, streams } = this.props;


    if (this.context?.client) {
      const { client: socket } = this.context;
      const removedStream = streams.find(stream => stream.username === username);

      if (removedStream) {
        const roomName = removedStream.parent_streamer || removedStream.username;
        const streamsUsingRoom = streams.filter(stream => stream.parent_streamer === roomName || stream.username === roomName);

        /**
         * If only the current stream was using the chat room, close the room
         */
        if (streamsUsingRoom.length === 1) {
          socket.emit(events.LEAVE_ROOM, {
            roomId: removedStream.roomId,
          });
        }
      }
    }

    removeStream(username);
  }

  getDefaultPlayer() {
    const { defaultPlayer, isLargeMulti, performantMultis } = this.props;

    if (isLargeMulti && performantMultis) {
      return STREAMSHOTS;
    }

    return defaultPlayer;
  }

  render() {
    const {
      streams, toggleStreamFocus, focused,
      stopRecording, recordings, playbackKeys,
      defaultVjsTech, showBitrates, viewLayout,
    } = this.props;
    const { useFrames, width, height } = this.state;
    let frames = null;

    if (useFrames) {
      frames = memoStreamLayout(new Frame(0, 0, width, height), streams.length, focused !== null);
    }

    const removeStream = this.removeStream.bind(this);

    const defaultPlayer = this.getDefaultPlayer();

    return (
      <div ref={this.container} className={styles.StreamContainer}>
        {
            streams.map((stream, idx) => {
              const isPlayingRecording = recordings[stream.username];
              const isPlaying = stream.live || isPlayingRecording;

              const child = isPlaying ? (
                <StreamPlayer
                  multiSize={streams.length}
                  defaultPlayer={defaultPlayer}
                  defaultVjsTech={defaultVjsTech}
                  playbackKey={playbackKeys[stream.username]}
                  key={`player:${stream.id}`}
                  stream={stream}
                />
              ) : (
                <StreamPoster key={`poster:${stream.id}`} stream={stream} />
              );

              return (
                <div
                  className={cx('StreamContainerChild', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop', theater: viewLayout === 'Theater' })}
                  key={stream.id}
                  style={frames && frames[idx].style}
                >
                  <TitleBar showBitrates={showBitrates} stopRecording={isPlayingRecording && stopRecording} removeStream={streams.length > 1 && removeStream} toggleFocus={streams.length > 1 && toggleStreamFocus} stream={stream} />
                  <ContentBlur content={stream}>
                    {child}
                  </ContentBlur>
                </div>
              );
            })
        }
      </div>
    );
  }
}

/**
 * @param {RootState} state
 * @param {{ streams: Stream[], removeStream(username) => void }} ownProps
 */
function mapStateToProps(state, { streams, removeStream, location }) {
  const { viewLayout } = state.chat.options;

  return {
    viewLayout,
    descriptionHidden: isDescriptionHidden(state),
    chatWidth: getChatWidth(state),
    recordings: getPlayingRecordings(state),
    streams: visibleStreams(streams, location, state.watchUI.focused, state.entities.chatRooms, state.watchUI.playingRecordings),
    removeStream,
    focused: state.watchUI.focused,
    playbackKeys: getPlaybackKeys(state),
    defaultPlayer: state.preferences.defaultPlayer,
    defaultVjsTech: state.preferences.defaultVjsTech,
    performantMultis: state.preferences.performantMultis,
    isLargeMulti: isLargeMulti(state),
    showBitrates: state.currentUser.data && state.currentUser.data.role === 'admin',
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch, // Needed for the websockets
    /**
     * puts stream on focus (moves it to first position in state array)
     * @param {string|null} username the username, set to null to clear focus state
     */
    toggleStreamFocus(username) {
      return dispatch((dispatch, getState) => {
        /**
         * @type {RootState}
         */
        const { watchUI } = getState();

        dispatch(setStreamFocus(watchUI.focused === username ? null : username));
      });
    },

    /**
     * Stops the playback of a recording
     */
    stopRecording(username) {
      return dispatch(startPlayingRecording({ username }, null));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StreamContainer));
