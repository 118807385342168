// @ts-check
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chat from '~/components/Chat';
import Loading from '~/components/Loading';
import StreamGuard from '../StreamGuard';
import { getPlayingStreams } from '~/modules/streams';

import styles from './index.scss';

const StandaloneChat = ({ location }) => {
  const [error, setError] = useState(null);
  const streams = useSelector(getPlayingStreams);
  const overlayMode = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const isOBS = typeof window !== 'undefined' && window.navigator.userAgent.match(/OBS\/(\d+\.?)+/);
    return (isOBS || params.get('overlayMode') === '1') && params.get('overlayMode') !== '0';
  }, [location]);

  const onError = () => {
    setError('Chat crashed, reloading.');
    window.setTimeout(() => setError(null), 5000);
  };

  return (
    <StreamGuard noSkeleton>
      <div className={styles.StandaloneChat}>
        {error || !streams.length > 0
          ? <Loading message={error} />
          : <Chat className={styles.StandaloneChat__Instance} onError={onError} overlayMode={overlayMode} />}
      </div>
    </StreamGuard>
  );
};

export default StandaloneChat;
