import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router';

import {
  fetchFolders,
  updateImage,
  getImageById,
  UPLOADING_IMAGE,
  FETCHING_IMAGES,
  getFolders,
} from '~/modules/gallery';

import Loading from '~/components/Loading';

import ImageForm from '../Form';
import { getCurrentUser } from '~/modules/currentUser';

const EditImage = ({ match: { params: { imageId } }, history }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.loading[UPLOADING_IMAGE] || state.loading[FETCHING_IMAGES]);
  const currentUser = useSelector(getCurrentUser);

  const image = useSelector(state => getImageById(state, parseInt(imageId, 10)));
  const folders = useSelector(getFolders);

  useEffect(() => {
    dispatch(fetchFolders(currentUser.username));
  }, []);

  if (isLoading) return <Loading />;

  const onSubmit = (values) => {
    dispatch(updateImage({
      ...values,
      published_at: values.uploadMode === 'SCHEDULE' ? values.published_at : null,
    })).then((response) => {
      history.push(generatePath('/gallery/image/:imageId', { imageId: response.id }));
    });
  };

  return (
    <ImageForm
      isLoading={isLoading}
      image={image}
      folders={folders}
      onSubmit={onSubmit}
      files={[]}
    />
  );
};

export default EditImage;
