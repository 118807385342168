/* node_modules */
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import Dropzone from 'react-dropzone';
import classNames from 'classnames/bind';
import { List, arrayMove } from 'react-movable';

/* local imports */
import { preferences } from '~/services/storage';
import FormattedMessage from '~/components/common/FormattedMessage';
import Loading from '~/components/Loading';
import SelectorButton, { Selection } from '~/components/Button/Selector';
import TextField from '~/components/Form/TextField';
import TagField from '~/components/Form/TagField';
import Checkbox from '~/components/Form/Checkbox';
import DateTime from '~/components/Form/DateTime';
import DescriptionEditor from '~/components/DescriptionEditor';
import Button from '~/components/Button';

/* style imports */
import styles from './ImageForm.scss';

const cx = classNames.bind(styles);
const MAXNAMELENGTH = 30;

const sliceName = (name) => {
  if (typeof name === 'string') {
    const diff = name.length - MAXNAMELENGTH + 3;
    if (diff > 0) {
      return `...${name.slice(diff, name.length)}`;
    }
    return name;
  }
};


export class FolderPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = { addingFolder: false };

    this.toggleAdding = this.toggleAdding.bind(this);
  }

  toggleAdding(e) {
    this.setState({ addingFolder: !this.state.addingFolder });
    this.onClick(e);
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    const { addingFolder } = this.state;
    const {
      folders, folder_id, handleChange, handleBlur, hideTitle,
    } = this.props;

    return (
      <div className={cx('ImageForm__FolderPicker')}>
        {!hideTitle && (
        <label>
          <FormattedMessage
            id="Gallery_UploadForm_Folder"
            defaultMessage="Folder"
          />
        </label>
        )}

        {addingFolder ? (
          <Field type="text" placeholder="Folder name" name="folder_name" onClick={this.onClick} onChange={handleChange} />
        ) : (
          <select
            placeholder="Select a folder..."
            name="folder_id"
            value={folder_id}
            onClick={this.onClick}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <FormattedMessage
              id="Gallery_UploadForm_NoFolder"
              defaultMessage="No folder"
            >
              {msg => <option value="">{msg}</option>}
            </FormattedMessage>
            {folders && folders.map((folder, i) => (
              <option key={i} value={folder.id}>
                {folder.name}
              </option>
            ))}
          </select>
        )}

        <span
          onClick={this.toggleAdding}
        >
          <i className="ion-plus-circled" />
          {addingFolder ? 'Cancel' : 'New folder'}
        </span>
      </div>
    );
  }
}

export class UploadPreview extends React.Component {
  constructor(props) {
    super(props);

    this.remove = this.remove.bind(this);
  }

  remove(e) {
    const { i, removeFile } = this.props;

    e.preventDefault();
    e.stopPropagation();

    removeFile(i);
  }

  render() {
    const {
      file,
      file: {
        name,
        data,
        path,
        preview,
        type,
        thumbnail,
      },
      isThumb,
      setThumb,
      minimal,
      i,
      error,
    } = this.props;

    const hasThumb = (name && !['mp4', 'webm', 'swf'].includes(name.split('.').pop())) || thumbnail;

    return (
      <div
        className={cx('ImageForm__UploadPreview', {
          'ImageForm__UploadPreview--isThumb': isThumb,
          'ImageForm__UploadPreview--minimal': minimal,
          'ImageForm__UploadPreview--hasError': error,
        })}
        key={`preview:${i}`}
      >
        {hasThumb ? (
          <Button
            onClick={!isThumb ? setThumb : null}
            color="transparent"
            title="Preview"
            className={cx('ImageForm__UploadPreviewThumb')}
          >
            <img src={thumbnail || data} alt="Thumbnail" />
            <span>
              {isThumb
                ? <FormattedMessage id="Gallery_UploadForm_IsThumb" defaultMessage="Current thumbnail" />
                : <FormattedMessage id="Gallery_UploadForm_SetThumb" defaultMessage="Set as thumb" />}
            </span>
          </Button>
        ) : <span>N/A</span>}


        {
          !minimal && (
          <div>
            <span>{sliceName(name)}</span>
            { error && (
              <div className={cx('ImageForm__UploadPreview--error')}>
                { error }
              </div>
            )}
          </div>
          )
        }

        <div>
          {!minimal && (
            <a
              className="ion-close-circled"
              onClick={this.remove}
              title="Remove"
              href="javascript:void"
            />
          )}
          {
            error && (
              <span role="img" className={cx('ImageForm__UploadPreview--errorIcon')} />
            )
          }
        </div>
      </div>
    );
  }
}

export const UPLOAD_MODES = {
  UPLOAD: 0,
  QUEUE: 1,
  SCHEDULE: 2,
};

class ImageForm extends React.Component {
  constructor(props) {
    super(props);

    this.setMode = this.setMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  setMode(e, setFieldValue) {
    setFieldValue('uploadMode', e.currentTarget.dataset.name);
  }

  handleChange(e, setFieldValue) {
    const {
      name,
      checked,
    } = e.currentTarget;

    if (name === 'nsfw') preferences.setItem('nsfw-last-value', checked);

    setFieldValue(name, checked);
  }

  handleDrop(files, values, setFieldValue) {
    const {
      onDrop,
    } = this.props;

    if (!values.title || values.title === '') setFieldValue('title', files[0].name);
    if (onDrop) onDrop(files);
  }

  handlePaste(e, values, setFieldValue) {
    const file = e.clipboardData.files[0];

    if (e.target.tagName !== 'INPUT' && e.target.tagName !== 'TEXTAREA' || e.target.contentEditable === 'true') {
      e.preventDefault();
      if (file) this.handleDrop([file], values, setFieldValue);
    }
  }

  validate(values) {
    const { files } = this.props;

    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    const fileSizeErrors = files.some(file => this.validateFile(file));

    if (fileSizeErrors) {
      errors.files = 'One or more files are too large';
    }

    return errors;
  }

  validateFile(file) {
    const { sizeLimit } = this.props;

    if (file.size > (sizeLimit * 1024 * 1024)) {
      return (
        <FormattedMessage
          id="Gallery_UploadForm_TooLarge"
          defaultMessage="File size too large, {premiumLink}"
          values={{
            premiumLink: (
              <Link onClick={e => e.stopPropagation()} to="/account/premium">
                <FormattedMessage id="Gallery_UploadForm_Premium" defaultMessage="buy premium to increase the limit" />
              </Link>
            ),
          }}
        />
      );
    }

    return null;
  }

  render() {
    const {
      image,
      files,
      setFiles,
      folders,
      removeFile,
      onSubmit,
      onDrop,
      setThumb,
      isLoading,
    } = this.props;

    if (image) image.uploadMode = image.published_at ? (image.published_at < new Date() ? 'SCHEDULE' : null) : 'QUEUE';

    return (
      <div className={cx({ 'ImageForm--loading': isLoading })}>
        {isLoading && <Loading />}
        <Formik
          initialValues={image ? {
            ...image,
            thumbnail_id: image.thumbnail ? image.thumbnail.id : null,
            tags: image.tags.map(tag => tag.title),
          } : {
            title: '',
            description: '',
            folder_name: null,
            folder_id: null,
            thumbnail_id: 0,
            nsfw: preferences.getItem('nsfw-last-value') || false,
            tags: [],
            published_at: null,
          }}
          onSubmit={onSubmit}
          validate={values => this.validate(values)}
        >
          {({
            errors, values, setFieldValue, handleChange, handleBlur,
          }) => (
            <Form>
              {!image ? (
                <Fragment>
                  <label className="pick-file-label">
                    <FormattedMessage
                      id="Gallery_UploadForm_Select"
                      defaultMessage="Select image"
                    />
                  </label>
                  <div
                    role="button"
                    tabIndex={0}
                    contentEditable
                    spellCheck="false"
                    className={cx('NonSelectable')}
                    onCut={e => e.preventDefault()}
                    onKeyPress={e => e.preventDefault()}
                    onKeyDown={e => e.preventDefault()}
                    onKeyUp={e => e.preventDefault()}
                  >
                    <PasteFile
                      handlePaste={() => this.handlePaste()}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                    <Dropzone onDrop={droppedFiles => this.handleDrop(droppedFiles, values, setFieldValue)}>
                      {({ getRootProps, getInputProps, isDragActive }) => (
                        <div
                          {...getRootProps()}
                          data-testid="fileinput"
                          className={cx('ImageForm__Dropzone', { 'ImageForm__Dropzone--active': isDragActive })}
                        >
                          {!files.length && (
                            <h2>
                              <FormattedMessage id="Gallery_file_prompt" defaultMessage="Drop file here to upload." />
                            </h2>
                          )}
                          <input {...getInputProps()} />
                          <List
                            values={files}
                            onChange={({ oldIndex, newIndex }) => {
                              setFiles(arrayMove(files, oldIndex, newIndex));
                              if (values.thumbnail_id === oldIndex) {
                                setFieldValue('thumbnail_id', newIndex);
                              }
                            }}
                            renderList={({ children, props }) => <div {...props} className={cx('ImageForm__Uploads')}>{children}</div>}
                            renderItem={({ value, index, props }) => (
                              <div {...props}>
                                <div style={{ cursor: 'grab' }}>
                                  <UploadPreview
                                    file={value}
                                    i={index}
                                    removeFile={removeFile}
                                    isThumb={values.thumbnail_id === index}
                                    setThumb={(e) => { e.stopPropagation(); setFieldValue('thumbnail_id', index); }}
                                    error={this.validateFile(value)}
                                  />
                                </div>
                              </div>
                            )}
                          />
                          {!!files.length && (
                            <>
                              <br />
                              <span className={cx('ImageForm__Addmore')}>
                                <i className="ion-plus-circled" />
                                Add more
                              </span>
                            </>
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Fragment>
              ) : (
                <div className={cx('ImageForm__Uploads', 'ImageForm__Uploads--minimal', 'ImageForm__Uploads--fullWidth')}>
                  {image.images && image.images.map((file, i) => {
                    const {
                      id,
                      image: thumbnail,
                      is_video: isVideo,
                      is_flash: isFlash,
                    } = file;

                    return (
                      <UploadPreview
                        file={{
                          thumbnail: thumbnail[isVideo || isFlash ? 'vthumb' : 'thumb'].url,
                        }}
                        isThumb={(values.thumbnail_id || undefined) === id}
                        setThumb={() => {
                          setFieldValue('thumbnail_id', id || '');
                        }}
                        minimal
                      />
                    );
                  })}
                </div>
              )}

              <TextField name="title" errors={errors.title} fullWidth>
                <FormattedMessage
                  id="Gallery_UploadForm_Title"
                  defaultMessage="Image Title (required)"
                />
              </TextField>

              <div>
                <label>
                  <FormattedMessage
                    id="GalleryForm_Description"
                    defaultMessage="Description"
                  />
                </label>
                <DescriptionEditor setFieldValue={setFieldValue} fieldProps={{ name: 'description' }} />
              </div>

              <FolderPicker
                {...{ folders, handleChange, handleBlur }}
                folder_id={values.folder_id}
              />
              <TagField
                name="tags"
                value={values.tags}
                onChange={value => setFieldValue('tags', value)}
                fullWidth
              >
                <FormattedMessage id="GalleryForm_Tags" defaultMessage="Tags" />
              </TagField>
              <Checkbox
                name="nsfw"
                checked={values.nsfw}
                onChange={e => this.handleChange(e, setFieldValue)}
              >
                <FormattedMessage
                  id="Gallery_UploadForm_Adult"
                  defaultMessage="This image is {adult} content"
                  values={{
                    adult: (
                      <span title="Adult includes partial and full nudity, suggestive or sexual content, or any sort of disturbing content.">
                        <FormattedMessage id="adult" defaultMessage="adult" />
                      </span>
                    ),
                  }}
                />
              </Checkbox>
              <SelectorButton type="submit" disabled={isLoading} value={UPLOAD_MODES[values.uploadMode]}>
                <Selection data-name="PUBLISH" onClick={e => this.setMode(e, setFieldValue)}>
                  Publish now
                </Selection>
                <Selection data-name="QUEUE" onClick={e => this.setMode(e, setFieldValue)}>
                  Add to queue
                </Selection>
                <Selection data-name="SCHEDULE" onClick={e => this.setMode(e, setFieldValue)}>
                  Publish at...
                </Selection>
              </SelectorButton>
              {values.uploadMode === 'SCHEDULE' && (
              <Fragment>
                <DateTime value={values.published_at} onChange={value => setFieldValue('published_at', value)} />
              </Fragment>
              )}
              {!image && (
              <Link to="/bulkupload" style={{ color: '#268cb5', textAlign: 'center', display: 'block' }}>
                <span title="This feature is currently under development, expect a few bugs" style={{ color: '#fff' }}>
                  [BETA]
                  {' '}
                </span>
                <FormattedMessage
                  id="Try_BulkUploader"
                  defaultMessage="Try our new bulk uploader"
                />
              </Link>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const PasteFile = ({ handlePaste, values, setFieldValue }) => {
  const callbackPaste = (e) => {
    handlePaste(e, values, setFieldValue);
  };

  useEffect(() => {
    window.document.addEventListener('paste', callbackPaste);
    return () => {
      window.document.removeEventListener('paste', callbackPaste);
    };
  });
  return null;
};

ImageForm.propTypes = {
  sizeLimit: PropTypes.number,
};

ImageForm.defaultProps = {
  sizeLimit: 5, // MB
};

export default ImageForm;
