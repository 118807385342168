import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import PiczelIcon from './PiczelCategoryIcon.png';

import styles from './StatusIcons.scss';

const Star = () => <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor" height="1em" viewBox="0 -960 960 960" width="1em"><path d="m354-247 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-80l65-281L80-550l288-25 112-265 112 265 288 25-218 189 65 281-247-149L233-80Zm247-350Z" /></svg>;
const ExclamationTriangle = () => <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor" height="1em" viewBox="0 -960 960 960" width="1em"><path d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z" /></svg>;
const CameraVideo = () => <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor" height="1em" viewBox="0 -960 960 960" width="1em"><path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h480q33 0 56.5 23.5T720-720v180l160-160v440L720-420v180q0 33-23.5 56.5T640-160H160Zm0-80h480v-480H160v480Zm0 0v-480 480Z" /></svg>;
const Shield = () => <svg xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="currentColor" height="1em" viewBox="0 -960 960 960" width="1em"><path d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v244q0 152-90.5 276.5T480-80Zm0-84q97-30 162-118.5T718-480H480v-315l-240 90v207q0 7 2 18h238v316Z" /></svg>;

const StatusIcons = ({ user }) => {
  const icons = user.icons || [];
  const intl = useIntl();

  return (
    <div className={styles.StatusIcons}>
      {
        icons.map((icon) => {
          if (icon.display_name === 'Premium user') {
            return (
              <div className={styles.StatusIcons__Icon}>
                <i
                  role="img"
                  title={`${icon.display_name} (${icon.description})`}
                >
                  <Star />
                </i>
              </div>
            );
          }

          return (
            <div className={styles.StatusIcons__Icon}>
              <img src={icon.icon.url} title={`${icon.display_name} (${icon.description})`} alt={icon.display_name} key={icon.id || icon.display_name} />
            </div>
          );
        })
      }
      {
        !user.piczel_user_id && (
          <div className={styles.StatusIcons__Icon}>
            <i
              role="img"
              title={intl.formatMessage({ id: 'Chat_AnonIcon', defaultMessage: 'Anonymous user' })}
            >
              <ExclamationTriangle />
            </i>
          </div>
        )
      }

      {
        user.role === 'streamer' && (
          <div className={styles.StatusIcons__Icon}>
            <i
              role="img"
              title={intl.formatMessage({ id: 'Chat_StreamerIcon', defaultMessage: 'Streamer' })}
            >
              <CameraVideo />
            </i>
          </div>
        )
      }

      {
        user.role === 'mod' && (
          <div className={styles.StatusIcons__Icon}>
            <i
              role="img"
              title={intl.formatMessage({ id: 'Chat_ModIcon', defaultMessage: 'Moderator' })}
            >
              <Shield />
            </i>
          </div>
        )
      }

      {
        user.role === 'admin' && (
          <div className={styles.StatusIcons__Icon} style={{ display: 'flex' }}>
            <img src={PiczelIcon} alt={intl.formatMessage({ id: 'Chat_AdminIcon', defaultMessage: 'Piczel.tv admin' })} title={intl.formatMessage({ id: 'Chat_AdminIcon', defaultMessage: 'Piczel.tv Admin'})} />
          </div>
        )
      }
    </div>
  );
};

StatusIcons.propTypes = {
  user: PropTypes.shape({
    chat_icons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      icon: PropTypes.string,
      display_name: PropTypes.string,
    })),
    piczel_user_id: PropTypes.number,
    role: PropTypes.string,
  }).isRequired,
};

export default React.memo(StatusIcons);
