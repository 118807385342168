import { schema } from 'normalizr';
import config from '~/config';
import { sortById } from '~/helpers/data';
import errorHandling from './_errorHandling';
import { userSchema } from './user';

// Schema
export const comment = new schema.Entity('comment', {
  user: userSchema,
});

// State
const INITIAL_STATE = {
  byId: {},
  allIds: [],
};

// Constants
const CommentType = {
  GalleryImage: '/gallery/image/:id/comments',
};

// Actions
const UPDATE_COMMENT = 'piczel/comments/UPDATE_COMMENT';
const REMOVE_COMMENT = 'piczel/comments/REMOVE_COMMENT';

const UPDATE_COMMENTS = 'piczel/comments/UPDATE_COMMENTS';
const RESET_COMMENTS = 'piczel/comments/RESET_COMMENTS';

// Reducer
export default function commentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_COMMENT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.id]: {
            ...state.byId[action.id],
            ...action.payload,
          },
        },
      };

    case REMOVE_COMMENT:
      return {
        ...state,
        allIds: state.allIds.filter(id => id !== action.id),
      };

    case UPDATE_COMMENTS:
      return sortById(state, action.payload);

    case RESET_COMMENTS:
      return INITIAL_STATE;

    default: return state;
  }
}

// Action creators
export function updateComment(id, payload) {
  return { type: UPDATE_COMMENT, id, payload };
}

export function removeComment(id) {
  return { type: REMOVE_COMMENT, id };
}

export function updateComments(payload) {
  return { type: UPDATE_COMMENTS, payload };
}

export function resetComments() {
  return { type: RESET_COMMENTS };
}

function processComments(comments) {
  let result = [];
  const flatten = (comment) => {
    return Object.assign({}, comment, {
      replies: comment.replies.map(r => r.id)
    });
  };

  for (const comment of comments) {
    if (!comment.replies || !comment.has_replies) {
      result.push(comment);
    } else {
      let newComment = flatten(comment);

      result.push(newComment);

      result = result.concat(processComments(comment.replies));
    }
  }

  return result;
}


export function sendComment(type, id, content, reply_to = null, comment_hidden = '') {
  return function (dispatch, getState, fetch) {
    let fetchUrl;

    if (type === 'GalleryImage') fetchUrl = `${config.api}/gallery/image/${id}/comments`;

    return fetch(fetchUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content,
        comment_hidden,
        reply_to_id: reply_to,
      }),
    }).then(res => errorHandling(dispatch, res))
      .then((comment) => {
        dispatch(updateComments(processComments([comment])));

        if (comment.reply_to_id) {
          const parentComment = getState().comments.byId[comment.reply_to_id];
          dispatch(updateComment(parentComment.id, {
            has_replies: true,
            replies_count: parentComment.repliesCount + 1,
            replies: [...parentComment.replies, comment.id],
          }));
        }
      });
  };
}

export function deleteComment(id) {
  return function (dispatch, getState, fetch) {
    return fetch(`${config.api}/comments/${id}`, {
      method: 'DELETE',
    }).then(res => errorHandling(dispatch, res))
      .then(() => {
        const comment = getCommentById(getState(), id);

        // Keep comment in tree if it has replies.
        if (comment.has_replies) {
          return dispatch(updateComment(id, {
            content: '[deleted]',
            content_processed: '<p>[deleted]</p>',
            user: {
              avatar: { avatar: { url: 'https://piczel.tv/static/images/avatar_default.png' } },
              follower_count: null,
              following: null,
              gallery: {},
              id: null,
              'premium?': false,
              role: 'user',
              username: 'Deleted',
            },
          }));
        }

        // Delete from parents replies
        if (comment.reply_to_id) {
          const parentComment = getCommentById(getState(), comment.reply_to_id);

          dispatch(updateComment(parentComment.id, {
            replies: parentComment.replies.filter(reply => reply.id !== id).map(reply => reply.id),
          }));
        }

        return dispatch(removeComment(id));
      });
  };
}

export function fetchComments(type, id) {
  return function (dispatch, getState, fetch) {
    dispatch(resetComments());

    return fetch(`${config.api}/${CommentType[type].replace(':id', id)}`)
      .then(res => errorHandling(dispatch, res))
      .then((json) => {
        dispatch(updateComments(processComments(json)));
      });
  };
}

export function fetchCommentReplies(id) {
  return function (dispatch, getState, fetch) {
    const url = new URL(`${config.api}/comments/${id}/replies`);

    return fetch(url.toString())
      .then(res => errorHandling(dispatch, res))
      .then((json) => {
        dispatch(updateComments(processComments(json)));
        dispatch(updateComment(id, {
          replies: json.map(comment => comment.id),
        }));
      });
  };
}

// Selectors
export function getCommentById(state, id) {
  const comment = { ...state.comments.byId[id] };

  if (comment.has_replies && comment.replies) {
    comment.replies = comment.replies.map((replyId) => {
      if (typeof replyId === 'object') return replyId;

      return getCommentById(state, replyId);
    });
  }

  return comment;
}

export function getCommentsById(state, parentId) {
  const response = state.comments.allIds
    .map(id => state.comments.byId[id])
    .filter(item => !item.reply_to_id && item.parent_id === parentId)
    .map(comment => getCommentById(state, comment.id));

  return response;
}
