import React from 'react';
import classNames from 'classnames/bind';
import * as Sentry from '@sentry/browser';

import style from './index.scss';

const cx = classNames.bind(style);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    
    // Display fallback UI
    this.setState({ hasError: true });

    console.error(error, errorInfo);
    Sentry.captureException(error);

    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      if (process.env.NODE_ENV !== 'development') {
        window.location.reload();
      }
    }, 5000);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div>
            <div>
              <div className={cx('ErrorBoundary')}>
                <img alt="Error logo" src="/img/piczel-error.png" />
                <h1>Something went wrong</h1>

                <p>
                  There was an error while processing your request, our developers have
                  been notified and will be working on a fix shortly.
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
