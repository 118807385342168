// @ts-check
import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes, { array } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import className from 'classnames/bind';
import { batchActions } from 'redux-batched-actions';

import Button from '~/components/Button';
import Checkbox from '~/components/Form/Checkbox';
import SidePane from './SidePane';
import ColorPicker from './ColorPicker';
import WidthSelector from './WidthSelector';
import ViewLayoutSelector from './ViewLayoutSelector';
import Modal from './Modal';
import CooldownRange from './CooldownRange';

import { resizeChat } from '~/modules/streams';
import {
  setHighlightedMessage,
  setActiveRoom,
  getOptions,
  setOptions,
  getNotificationReminder,
  setNotificationReminder,
  SoundOptions,
  getBlockList,
  isMessageWithMention,
} from '~/modules/chat';
import Avatar from '../Avatar';
import BlockButton from './BlockButton';

import { ChatContext, events, roleLevels } from './ChatContext';

import styles from './Options.scss';
import Tooltip from '../Tooltip';

const cx = className.bind(styles);

// boolean options
const knownOptions = [
  'displayColors',
  'animateGifs',
  'disableTimestamps',
  'soundOnlyWhenUnfocused',
  'mentionNotifications',
  'roleMentionNotifications',
  'everyoneMentionNotifications',
  'Chat_joinRoom',
  'Chat_banned',
  'Chat_kick',
  'Chat_ban',
  'Chat_topic',
  'raffleWinnerAnimation',
];

// non-boolean options
const rawOptions = [
  'playSounds',
  'zoom',
  'emojiSize',
  'color',
  'messageDisplay',
  'pmsDisplay',
  'chatFontSize',
  'initialWidth',
  'chatFont',
  'viewLayout',
];

export const NEW_ACCOUNT_BLOCK_TIME = 14 * 24 * 60 * 60;

export const defaultFont = 'andika';

export const fontOptions = {
  'open-dyslexic': 'OpenDyslexic',
  'open-sans': 'OpenSans',
  'comicneue': 'ComicNeue',
  'merriweather': 'Merriweather',
  'quicksand': 'Quicksand',
  'andika': 'Andika',
};

async function loadFonts(fontSelect) {
  switch(fontSelect){
    case 'OpenDyslexic':
      import("../../scss/fonts/opendislexic.scss");
      break;
    case 'OpenSans':
      import("../../scss/fonts/opensans.scss");
      break;
    case fontOptions['comicneue']:
      return import('~/scss/fonts/comicneue.scss');

    case fontOptions['merriweather']:
      return import('~/scss/fonts/merriweather.scss');

    default:
      console.warn(`Options: Attempted to load unknown font ${fontSelect}`);
  }
};

const ZoomInput = ({ value, onChange, ...props }) => (
  <div className={`${styles.Options__Option} ${styles.Options__Zoom}`}>
    <span className={styles.Options__OptionLabel}>
      <FormattedMessage id="ChatOptions_Zoom" defaultMessage="Zoom level: {zoom}%" values={{ zoom: Math.round(value * 100) }} />
    </span>

    <input className={styles.Options__OptionInput} step={0.1} type="range" min={0.5} max={2} value={value} onChange={onChange} {...props} />
  </div>
);

const imagesOfEmojisRegExp = /<img src="\/emoji\/(\S+)" title="(\S+)" alt="(\S+)" \/>|<img width="(\S+)" height="(\S+)" src="(\S+)" title="(\S+)" alt="(\S+)" \/>/gm;

const transformTextToEmoji = (text) => {
  const splitCodeEmoji = text.split('_');
  try {
    return String.fromCodePoint(splitCodeEmoji.map(code => `0x${code}`));
  } catch (error) {
    return null;
  }
};

const transformEmojis = images => images.map(image => {
  const emoji = transformTextToEmoji(image.slice(24, image.indexOf('.svg')));
  if (emoji) return emoji;

  const element = document.createElement('div');
  element.innerHTML = image;
  return element.firstElementChild?.getAttribute("title");
});


const formatNotification = (message) => {
  const imagesOfEmojis = message.match(imagesOfEmojisRegExp) || [];
  const emojis = transformEmojis(imagesOfEmojis);

  imagesOfEmojis.forEach((image, index) => {
    message = message.replace(image, emojis[index]);
  });

  const p = document.createElement('p');
  p.innerHTML = message;

  return p.innerText;
};


const Options = ({ isOpen, currentRoom, currentUser }) => {
  const { client: socket } = useContext(ChatContext);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [changingZoom, setChangingZoom] = useState(false);
  const [blockListOpen, setBlockListOpen] = useState(false);

  const notificationsSupported = typeof window !== 'undefined' && 'Notification' in window;

  const blockList = useSelector(getBlockList);
  const notificationReminder = useSelector(getNotificationReminder);
  const { animateGifs, mentionNotifications, roleMentionNotifications, everyoneMentionNotifications, playSounds, displayColors, emojiSize, viewLayout, zoom, messageDisplay, pmsDisplay, disableTimestamps, chatFontSize, chatFont, initialWidth, ...options } = useSelector(getOptions);
  const notificationsAllowed = notificationsSupported && Notification.permission === 'granted';
  const canEditRoom = currentUser && roleLevels[currentUser.role] >= roleLevels.streamer;
  
  useEffect(() => {
    const actions = [];
    actions.push(
      setOptions(knownOptions.reduce((obj, key) => {
        if (key in localStorage) {
          // eslint-disable-next-line no-param-reassign
          obj[key] = localStorage[key] === 'true';
        }
  
        return obj;
      }, {})),
    );

    if ('notificationReminder' in localStorage) {
      actions.push(setNotificationReminder(localStorage.notificationReminder === 'true'));
    }


    actions.push(setOptions(rawOptions.reduce((obj, key) => {
      if (key in localStorage) {
        // eslint-disable-next-line no-param-reassign
        obj[key] = localStorage[key];
      }

      return obj;
    }, {})));

    if ('initialWidth' in localStorage) {
      actions.push(resizeChat(`${localStorage.initialWidth}px`));
    }

    dispatch(batchActions(actions));
  }, [dispatch]);

  useEffect(() => {
    if (!socket) return;

    function mentionHandler({ data: { roomId: messageRoomId, message } }) {
      const mentionsMe = isMessageWithMention(currentUser, message);
      if (!mentionsMe) return;

      const mention = mentionsMe[1].toLowerCase();
      const username = currentUser.username.toLowerCase();
      const mentionType = mention === username ? 'user' : (mention === 'everyone' ? 'everyone' : 'role');

      if (notificationsSupported && Notification.permission === 'granted') {
        const enabled = (mentionType === 'user' && mentionNotifications) || (mentionType === 'everyone' && everyoneMentionNotifications) || (mentionType === 'role' && roleMentionNotifications);
        
        if (enabled) {
          const notification = new Notification(message.user.username, {
            body: formatNotification(message.text),
            icon: `https://piczel.tv/api/avatars/${message.user.username}`,
            tag: message.id,
          });
  
          notification.onclick = () => {
            window.focus();
            dispatch(setActiveRoom(messageRoomId, true));
            dispatch(setHighlightedMessage(message.id));
          };
        }
      }
    }

    socket.on(events.ADD_MESSAGE, mentionHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      socket.off(events.ADD_MESSAGE, mentionHandler);
    };
  }, [mentionNotifications, everyoneMentionNotifications, roleMentionNotifications, socket, dispatch, currentUser]);

  useEffect(() => {
    loadFonts(fontOptions[chatFont]);
  }, [chatFont]);

  const setOption = useCallback((key, value) => {
    dispatch(setOptions({ [key]: value }));
    localStorage.setItem(key, value);
  }, [dispatch]);

  const onNotificationChange = key => (e) => {
    const { checked } = e.target;

    if (checked && notificationsSupported && !notificationsAllowed) {
      e.persist();
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          dispatch(setOptions({ [key]: checked }));
        } else {
          e.preventDefault();
        }
      });
    } else {
      dispatch(setOptions({ [key]: checked }));
    }

    localStorage.setItem(key, checked);
  };

  const updateRoom = useCallback((data) => {
    if (!socket) return;

    socket.emit(events.UPDATE_ROOM, {
      roomId: currentRoom.id,
      data,
    });
  }, [socket, currentRoom]);

  const changeWidth = useCallback((value) => {
    setOption('initialWidth', parseInt(value, 10));
    dispatch(resizeChat(value));
  }, [dispatch, setOption]);

  const changeLayout = useCallback((value) => {
    setOption('viewLayout', value);
  }, [setOption]);

  const setAllowNewAccounts = useCallback((allowed) => {
    const payload = { min_account_age: allowed ? 0 : NEW_ACCOUNT_BLOCK_TIME };

    if (!allowed) {
      payload.allow_anon = false;
    }

    updateRoom(payload);
  }, [updateRoom]);

  return (
    <>
      {
        changingZoom && (
          <Modal className={styles.Options} title={<FormattedMessage id="ChatOptions__ZoomTitle" defaultMessage="Chat zoom level" />} close={() => setChangingZoom(false)}>
            {/* Use the Options classname to keep zoom constant */}
            <ZoomInput value={zoom} onChange={e => setOption('zoom', e.target.value)} />
          </Modal>
        )
      }

      {
        blockListOpen && (
          <Modal className={styles.Options} title={<FormattedMessage id="ChatOptions__BlockList" defaultMessage="Blocked users" />} close={() => setBlockListOpen(false)}>
            {
              blockList.map(user => (
                <div className={styles.BlockedUser} key={user.userId}>
                  <Avatar className={styles.BlockedUser__Avatar} username={user.username} />

                  <div className={styles.BlockedUser__Username}>
                    { user.username }
                  </div>
                  
                  <BlockButton user={user} />
                </div>
              ))
            }

            {
              blockList.length === 0 && (
                <div style={{ opacity: 0.75, textAlign: 'center' }}>
                  <FormattedMessage id="ChatOptions_BlockListEmpty" defaultMessage="Blocked users list is empty" />
                </div>
              )
            }
          </Modal>
        )
      }

      <SidePane className={cx('Options', { 'Options--ChangingZoom': changingZoom })} isOpen={isOpen} title="Options">
        {
          canEditRoom && (
          <>
            <Checkbox className={styles.Options__Setting} checked={currentRoom.deletion_enabled} onChange={e => updateRoom({ deletion_enabled: e.target.checked })} name="ChatOptions_DeletionEnabled">
              <FormattedMessage id="ChatOptions_DeletionEnabled" defaultMessage="Allow message deletion in room" />
            </Checkbox>

            <CooldownRange value={currentRoom.cooldown} onChange={e => updateRoom({ cooldown: e.target.value })} />
         

            <div className={styles.Options__Title}>
              <FormattedMessage id="ChatOptions_LinkPolicy" defaultMessage="Room link policy" />
            </div>
            <div className={styles.Options__Option}>

              <select className={styles.Options__OptionInput} value={currentRoom.link_policy} onChange={e => updateRoom({ link_policy: e.target.value })}>
                <option value="allow">{ intl.formatMessage({ id: 'ChatOptions_LinkPolicyAllow', defaultMessage: 'Allow links' }) }</option>
                <option value="noparse">{ intl.formatMessage({ id: 'ChatOptions_LinkPolicyNoparse', defaultMessage: 'Links allowed but not clickable' }) }</option>
                <option value="block">{ intl.formatMessage({ id: 'ChatOptions_LinkPolicyBlock', defaultMessage: 'Block messages with links' }) }</option>
              </select>
            </div>

            <hr className={styles.Options__Separator} />
            <div className={styles.Options__Option}>
              <Tooltip text={{ id: 'ChatOptions_MinAccountAgeHelp', defaultMessage: 'When disabled, only accounts older than 2 weeks are allowed to talk, this does NOT block anon. users from talking' }}>
                <Checkbox 
                  name="ChatOptions_MinAccountAge"
                  className={styles.Options__AnonToggle}
                  checked={!currentRoom.min_account_age}
                  onChange={(e) => {
                    const allowNewAccounts = e.target.checked;
                    setAllowNewAccounts(allowNewAccounts);
                  }}>
                  <FormattedMessage id="ChatOptions_MinAccountAge" defaultMessage="Allow new users to chat" />
                </Checkbox>
              </Tooltip>
            </div>

            <Checkbox
              className={styles.Options__Setting}
              name="ChatOptions_AllowAnon"
              checked={currentRoom.allow_anon}
              onChange={e => {
                const allowsNewAccounts = !currentRoom.min_account_age;
                const allowAnon = e.target.checked;

                updateRoom({ 
                  allow_anon: allowAnon,
                  min_account_age: (allowAnon && !allowsNewAccounts) ? 0 : undefined,
                });
              }}
            >
              <FormattedMessage id="ChatOptions_AllowAnon" defaultMessage="Allow anonymous users in chat" />
            </Checkbox>

            <Tooltip
              text={{
                id: 'StreamPrivacy_Moderated',
                defaultMessage: `When enabled, only mods and users explicitly given voice permission can speak in your chat`,
              }}>
              <Checkbox
                className={styles.Options__Setting}
                checked={currentRoom.moderated}
                onChange={e => updateRoom({ moderated: e.target.checked })}
                name="ChatOptions_Moderated"
              >
                <FormattedMessage id="ChatOptions_Moderated" defaultMessage="Use moderated chat" />
              </Checkbox>
            </Tooltip>
            <hr className={styles.Options__Separator} />
          </>
          )
        }
        
        {blockList.length > 0 && (
        <div>
          <div className={styles.Options__Title}>
            <FormattedMessage id="ChatOptions_Privacy" defaultMessage="Privacy" />
          </div>

          <Button onClick={() => setBlockListOpen(true)} color="transparent">
            <FormattedMessage id="ChatOptions_OpenBlocklist" defaultMessage="Manage blocked users" />
          </Button>
          
          <hr className={styles.Options__Separator} />
        </div>
        )}


        <div>
          <ColorPicker className={styles.Options__Option} color={options.color} setColor={color => setOption('color', color)}>
            <FormattedMessage id="ChatOptions_Color" defaultMessage="Username color" />

            <button type="button" className={styles.Options__ResetColor} onClick={() => setOption('color', '#3db9ea')}>
              Reset
            </button>
          </ColorPicker>
        </div>

        <Checkbox className={styles.Options__Setting} checked={displayColors} onChange={e => setOption('displayColors', e.target.checked)} name="DisplayColors">
          <FormattedMessage id="ChatOptions_DisplayColors" defaultMessage="Display username colors" />
        </Checkbox>

        <p className={styles.Options__Title}>
          <FormattedMessage id="ChatOptions__Appearance" defaultMessage="Appearance" />
        </p>

        <ViewLayoutSelector className={styles.Options__Option} currentViewLayout={viewLayout} setViewLayout={changeLayout} />

        <div className={styles.Options__Option}>
          <label htmlFor="ChatOptions_EmojiSize" className={styles.Options__OptionLabel}>
            <FormattedMessage id="ChatOptions_EmojiSize" defaultMessage="Max custom emote size" />

            <select id="ChatOptions_EmojiSize" className={styles.Options__OptionInput} value={emojiSize} onChange={e => setOption('emojiSize', e.target.value)}>
              <option value={200}>200</option>
              <option value={100}>100</option>
              <option value={50}>50</option>
            </select>
          </label>
        </div>

        <WidthSelector className={styles.Options__Option} currentWidth={`${initialWidth}px`} setWidth={changeWidth} />

        <div className={cx('Options__Option', 'Options__Zoom')}>
          <Button className={styles.Options__OptionZoomButton} color="black" onClick={() => setChangingZoom(true)}>
            <FormattedMessage id="ChatOptions_Zoom" defaultMessage="Zoom level: {zoom}%" values={{ zoom: Math.round(zoom * 100) }} />
          </Button>
        </div>

        <div className={`${styles.Options__Option}`}>
          <label htmlFor="ChatMessageDisplayOpt" className={styles.Options__OptionLabel}>
            <FormattedMessage id="ChatOptions__MessageDisplay" defaultMessage="Message display" />
            <select id="ChatMessageDisplayOpt" className={styles.Options__OptionInput} value={messageDisplay} onChange={e => setOption('messageDisplay', e.target.value)}>
              <option value="normal" label={intl.formatMessage({ id: 'ChatOptions__Cozy', defaultMessage: 'Cozy' })}>{intl.formatMessage({ id: 'ChatOptions__Cozy', defaultMessage: 'Cozy' })}</option>
              <option value="compact" label={intl.formatMessage({ id: 'ChatOptions_Compact', defaultMessage: 'Compact' })}>{intl.formatMessage({ id: 'ChatOptions_Compact', defaultMessage: 'Compact' })}</option>
            </select>
          </label>
        </div>

        <div className={`${styles.Options__Option}`}>
          <label htmlFor="ChatPMsDisplayOpt" className={styles.Options__OptionLabel}>
            <FormattedMessage id="ChatOptions__PMsDisplay" defaultMessage="PMs display" />
            <select id="ChatPMsDisplayOpt" className={styles.Options__OptionInput} value={pmsDisplay} onChange={e => setOption('pmsDisplay', e.target.value)}>
              <option value="tab" label={intl.formatMessage({ id: 'ChatOptions__Tab', defaultMessage: 'Tab' })}>{intl.formatMessage({ id: 'ChatOptions__Tab', defaultMessage: 'Tab' })}</option>
              <option value="inline" label={intl.formatMessage({ id: 'ChatOptions_Inline', defaultMessage: 'Inline' })}>{intl.formatMessage({ id: 'ChatOptions_Inline', defaultMessage: 'Inline' })}</option>
              <option value="both" label={intl.formatMessage({ id: 'ChatOptions_Both', defaultMessage: 'Both' })}>{intl.formatMessage({ id: 'ChatOptions_Both', defaultMessage: 'Both' })}</option>
            </select>
          </label>
        </div>

        <div className={styles.Options__Option}>
          <label htmlFor="ChatOptions_ChatFont" className={styles.Options__OptionLabel}>
            <FormattedMessage id="ChatOptions_ChatFont" defaultMessage="Chat font" />

            <select id="ChatOptions_ChatFont" className={styles.Options__OptionInput} value={chatFont || defaultFont} onChange={e => setOption('chatFont', e.target.value)}>
              {
                Object.keys(fontOptions).map(value => (
                  <option value={value} key={value}>
                    {fontOptions[value]}
                  </option>
                ))
              }
            </select>
          </label>
        </div>

        <div className={`${styles.Options__Option} ${styles.Options__Range}`}>
          <span className={styles.Options__OptionLabel}>
            <FormattedMessage id="ChatOptions_FontSize" defaultMessage="Chat font size: {size}px" values={{ size: chatFontSize }} />
          </span>

          <input className={styles.Options__OptionInput} step={2} type="range" min={8} max={24} value={chatFontSize} onChange={e => setOption('chatFontSize', e.target.value)} />
        </div>

        <Checkbox className={styles.Options__Setting} name="Animate GIF emotes" checked={animateGifs} onChange={e => setOption('animateGifs', e.target.checked)}>
          <FormattedMessage id="ChatOptions_AnimateGifs" defaultMessage="Always animate GIF emotes" />
        </Checkbox>

        <Checkbox className={styles.Options__Setting} name="Show raffle confetti" checked={options.raffleWinnerAnimation} onChange={e => setOption('raffleWinnerAnimation', e.target.checked)}>
          <FormattedMessage id="ChatOptions_RaffleWinnerAnimation" defaultMessage="Show raffle confetti" />
        </Checkbox>

        <Checkbox className={styles.Options__Setting} name="Disable timestamps" checked={disableTimestamps} onChange={e => setOption('disableTimestamps', e.target.checked)}>
          <FormattedMessage id="ChatOptions_DisableTimestamps" defaultMessage="Disable timestamps" />
        </Checkbox>

        <p className={styles.Options__Title}>
          <FormattedMessage id="ChatOptions__Notifications" defaultMessage="Notifications" />
        </p>
        <div className={cx('Otions__Notifications', { 'Options__Notifications--Highlight': notificationReminder })}>
          <Checkbox className={styles.Options__Setting} name="MentionNotifications" checked={notificationsAllowed && mentionNotifications} onChange={onNotificationChange('mentionNotifications')}>
            <FormattedMessage id="ChatOptions_Mentions" defaultMessage="Show notifications for mentions" />
          </Checkbox>

          <Checkbox className={styles.Options__Setting} name="RoleMentionNotifications" checked={notificationsAllowed && roleMentionNotifications} onChange={onNotificationChange('roleMentionNotifications')}>
            <FormattedMessage id="ChatOptions_RoleMentions" defaultMessage="Show notifications for @role mentions" />
          </Checkbox>

          <Checkbox className={styles.Options__Setting} name="EveryoneMentionNotifications" checked={notificationsAllowed && everyoneMentionNotifications} onChange={onNotificationChange('everyoneMentionNotifications')}>
            <FormattedMessage id="ChatOptions_EveryoneMentions" defaultMessage="Show notifications for @everyone mentions" />
          </Checkbox>
        </div>

        <div className={styles.Options__Option}>
          <label className={styles.Options__OptionLabel} htmlFor="ChatPlaySoundsOpt">
            <FormattedMessage id="ChatOptions_PlaySounds" defaultMessage="Play sounds" />

            <select value={(playSounds === true || !playSounds) ? SoundOptions.mentions : playSounds} name="playsounds" onChange={e => setOption('playSounds', e.target.value)} className={styles.Options__OptionInput} id="ChatPlaySoundsOpt">
              <option value={SoundOptions.always}>
                { intl.formatMessage({ id: 'ChatOptions_NewMessages', defaultMessage: 'On new messages' }) }
              </option>

              <option value={SoundOptions.mentions}>
                { intl.formatMessage({ id: 'ChatOptions_MentionsOnly', defaultMessage: 'Only on messages mentioning me' }) }
              </option>

              <option value={SoundOptions.never}>
                { intl.formatMessage({ id: 'ChatOptions_NeverPlaySounds', defaultMessage: 'Never play sounds' }) }
              </option>
            </select>

          </label>
        </div>

        <Checkbox className={styles.Options__Setting} name="ChatOptions__PlaySoundsUnfocused" checked={options.soundOnlyWhenUnfocused} onChange={e => setOption('soundOnlyWhenUnfocused', e.target.checked)}>
          <FormattedMessage id="ChatOptions_PlaySoundsUnfocused" defaultMessage="Play sound only when chat is not focused" />
        </Checkbox>

        <p className={styles.Options__Title}>
          <FormattedMessage id="ChatOptions_StatusMessages" defaultMessage="Show status messages when..." />
        </p>

        <Checkbox className={styles.Options__Setting} name="Chat_joinRoom" checked={options.Chat_joinRoom} onChange={e => setOption('Chat_joinRoom', e.target.checked)}>
          <FormattedMessage id="ChatOptions_joinRoom" defaultMessage="User joins a room" />
        </Checkbox>

        <Checkbox className={styles.Options__Setting} name="Chat_leaveRoom" checked={options.Chat_leaveRoom} onChange={e => setOption('Chat_leaveRoom', e.target.checked)}>
          <FormattedMessage id="ChatOptions_leaveRoom" defaultMessage="User leaves a room" />
        </Checkbox>

        <Checkbox className={styles.Options__Setting} name="Chat_banned" checked={options.Chat_banned} onChange={e => setOption('Chat_banned', e.target.checked)}>
          <FormattedMessage id="ChatOptions_banned" defaultMessage="User is banned" />
        </Checkbox>

        <Checkbox className={styles.Options__Setting} name="Chat_topic" checked={options.Chat_topic} onChange={e => setOption('Chat_topic', e.target.checked)}>
          <FormattedMessage id="ChatOptions_topic" defaultMessage="Room topic is changed" />
        </Checkbox>
      </SidePane>
    </>
  );
};

Options.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  currentRoom: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default Options;
