/**
 * Useful, common hooks
 */
/// <reference path="../types/index.d.ts" />

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPlayingStream, setViewType, setAddingStream, TYPE_CUSTOM, fetchPlayingStream } from '~/modules/streams';
import { getEmoticonsByUsername, loadUsersEmoticons, deleteEmoticon, uploadEmoticon, updateEmoticon } from '~/modules/emoticons';
import { isLoading, setLoading } from '~/modules/loading';

/**
 * Returns a useCallback-wrapped function
 * that adds a stream to the current view
 */
export const useAddStream = () => {
  const dispatch = useDispatch();
  const { streams: { byUsername }, sections: { playing } } = useSelector(state => state.streams);

  /**
   * @type {(stream: Stream, e?: Event) => void}
   */
  const addStreamCallback = useCallback((stream, e) => {
    const username = stream.username || stream.slug;

    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (playing.indexOf(username) !== -1) {
      return;
    }


    dispatch(fetchPlayingStream(username, true));
    dispatch(setViewType(TYPE_CUSTOM));
    dispatch(setAddingStream(false));
  }, [dispatch, playing]);

  return addStreamCallback;
};

/**
 * Loads the emoticons for a user, provides CRUD functions
 * @param {String} username
 */
export const useEmoticons = (username, loadEmoticons = true) => {
  const dispatch = useDispatch();

  /**
   * @type {Emoticon[]}
   */
  const emoticons = useSelector(state => getEmoticonsByUsername(state, username));

  const loading = useSelector(state => isLoading(state.loading, 'EMOTICONS'));

  useEffect(() => {
    if (emoticons.length === 0 && loadEmoticons) {
      dispatch(setLoading('EMOTICONS', true));
      dispatch(loadUsersEmoticons()).then(() => {
        dispatch(setLoading('EMOTICONS', false));
      });
    }
  }, [dispatch]);

  /**
   * @param {Number} id
   */
  const remove = useCallback((id) => {
    return dispatch(deleteEmoticon(id));
  }, [dispatch]);

  const update = useCallback(emoticon => dispatch(updateEmoticon(emoticon)), [dispatch]);

  const upload = useCallback(emoticon => dispatch(uploadEmoticon(emoticon)), [dispatch]);

  return {
    loading,
    emoticons,
    remove,
    upload,
    update,
  };
};
