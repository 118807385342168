/* node_modules */
import React from 'react';
import Helmet from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';

/* local modules */
import StreamGuard from './views/StreamGuard';
import SignOut from './views/SignOut';
import BulkUpload from './views/BulkUpload';
import StandaloneChat from './views/StandaloneChat';
import Watch from './views/Watch';
import Gallery from '~/views/Gallery';
import GalleryImage from '~/views/Gallery/Image';
import ContestResults from '~/views/ContestResults';
import Player from '~/views/Player';
import DebugPlayer from './views/DebugPlayer';
import Home from '~/views/Home';

// const Home = loadable(() => import('~/views/Home'));
const Login = loadable(() => import('~/views/Login'));
const SignUp = loadable(() => import('~/views/SignUp'));
const Account = loadable(() => import('~/views/Account'));
const NotFound = loadable(() => import('~/views/404'));
const Premium = loadable(() => import('~/views/Premium'));
const Search = loadable(() => import('~/views/Search'));
const AdminPanel = loadable(() => import(/* webpackChunkName: 'Admin' */'~/views/Admin'));
const Tos = loadable(() => import(/* webpackChunkName: 'Tos' */'./pages/tos.mdx'));
const Privacy = loadable(() => import(/* webpackChunkName: 'Privacy' */'./pages/privacy.mdx'));
const Help = loadable(() => import('~/views/Help'));
const Style = loadable(() => import(/* webpackChunkName: 'StyleGuide' */'./pages/styleguide.mdx'));
const Optimization = loadable(() => import('~/views/Optimization'));

export const Routes = [
  // Home routes
  { exact: true, path: '/', component: Home },
  { exact: true, path: '/streams', component: Home },
  { exact: true, path: '/gallery', component: Home },
  { exact: true, path: '/feed', component: Home },

  // Gallery routes
  { path: '/gallery/image/:imageId', component: GalleryImage, hydrate: GalleryImage.hydrate },
  { path: '/gallery', component: Gallery },

  { path: '/bulkupload', component: BulkUpload },

  // Stream routes
  {
    path: '/watch/:names/:passwords?',
    component: () => (
      <StreamGuard>
        <Watch />
      </StreamGuard>
    ),
    hydrate: StreamGuard.hydrate,
  },
  { path: '/chat/:names', component: StandaloneChat },

  // User routes
  { path: '/login', component: Login },
  { path: '/signup', component: SignUp },
  { path: '/account', component: Account },
  { path: '/signout', component: SignOut },

  { exact: true, path: '/search', component: () => <Redirect to="/search/streams" /> },
  { path: '/search/:category?/:query?', component: Search },
  { path: '/premium', component: Premium },
  { path: '/help', component: Help },
  { path: '/optimizations', component: Optimization },

  // Admin
  { path: '/admin', component: AdminPanel },

  {
    path: '/tos',
    component: () => (
      <>
        <Helmet>
          <title>Terms of Service</title>
        </Helmet>
        <Tos />
      </>
    ),
  },

  {
    path: '/privacy',
    component: () => (
      <>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        <Privacy />
      </>
    ),
  },

  {
    path: '/styleguide',
    component: () => (
      <>
        <Helmet>
          <title>Style Guide</title>
        </Helmet>
        <Style />
      </>
    ),
  },

  {
    path: '/mascotcontest/results',
    component: ContestResults,
    exact: true,
  },

  {
    path: '/player/:streamId',
    component: Player,
  },
  {
    path: '/debugplayer',
    component: DebugPlayer,
  }
];

export default (
  <Switch>
    { Routes.map(route => <Route key={route.path} {...route} />) }
    <Route component={NotFound} />
  </Switch>
);
