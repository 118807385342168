import React from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';

import {
  uploadImage,
  fetchFolders,
  UPLOADING_IMAGE,
  UPLOADING_OTHERFILES,
  getFolders,
} from '~/modules/gallery';
import { isLoading } from '~/modules/loading';

import ImageForm from '../Form';

const fileSizeLimits = { // in megabytes
  regular: 10,
  premium: 100,
};

class UploadImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingFiles: false,
      files: [],
      thumbnailId: 0,
    };

    this.onDrop = this.onDrop.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.setFiles = this.setFiles.bind(this);
  }

  setFiles(files) {
    this.setState({ files });
  }

  onDrop(newFiles) {
    const { files } = this.state;
    this.setState({ loadingFiles: true });

    const processFiles = newFiles.map(file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve({
          name: file.name,
          size: file.size,
          type: file.type,
          data: reader.result,
        });
      };

      reader.onerror = reject;
    }));

    Promise.all(processFiles).then((values) => {
      this.setState({
        loadingFiles: false,
        files: [
          ...files,
          ...values,
        ],
      });
    });
  }

  onSubmit(values) {
    const { dispatch, history } = this.props;
    const { files, thumbnailId } = this.state;

    // preferences.setItem("nsfw-last-value", values.nsfw.toString());

    dispatch(uploadImage({
      ...values,
      files,
      thumbnail_id: thumbnailId,
      published_at: values.uploadMode === 'SCHEDULE' ? values.published_at : null,
    })).then((response) => {
      history.push(generatePath('/gallery/image/:imageId', { imageId: response.id }));
    });
  }

  removeFile(i) {
    const files = this.state.files.slice();
    files.splice(i, 1);

    this.setState({ files });
  }

  componentDidMount() {
    const { dispatch, currentUser } = this.props;

    dispatch(fetchFolders(currentUser.username));
  }

  render() {
    const {
      onDrop,
      onSubmit,
      removeFile,
      setFiles,
      state: {
        loadingFiles,
        files,
      },
      props: {
        isLoading,
        folders,
        currentUser
      },
    } = this;

    return (
      <ImageForm
        sizeLimit={(currentUser && currentUser['premium?']) ? fileSizeLimits.premium : fileSizeLimits.regular}
        {...{
          onDrop,
          onSubmit,
          removeFile,
          setFiles,
          files,
          folders,
          isLoading: isLoading || loadingFiles,
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser.data,
  folders: getFolders(state),
  isLoading: isLoading(state.loading, UPLOADING_IMAGE)
    || isLoading(state.loading, UPLOADING_OTHERFILES),
});

export default connect(mapStateToProps)(UploadImage);
