import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import {
  fetchImage,
  getImageById,
  FETCHING_IMAGES,
} from '~/modules/gallery';

import Loading from '~/components/Loading';
import ErrorHandler from '~/components/ErrorHandler';
import PasswordPrompt from '../PasswordPrompt';

import View from './View';
import Edit from './Edit';

const GalleryImage = ({ match, match: { params: { imageId } } }) => {
  const dispatch = useDispatch();
  const [errorCode, setError] = useState(null);
  const [isProtected, setProtected] = useState(false);
  const [password, setPassword] = useState(null);
  const isLoading = useSelector(state => state.loading[FETCHING_IMAGES]);
  const image = useSelector(state => getImageById(state, parseInt(imageId, 10)));

  const loadData = () => {
    dispatch(fetchImage(imageId, password))
      .then((res) => {
        if (res.image && typeof res.image[0] === 'string') {
          setProtected(true);
          setPassword(null);
        }

        if (res.status === 'error') setError(res.code);
      });
  };

  useEffect(() => {
    if (!image) loadData();
  }, [imageId]);

  // If protected and missing correct password, show password prompt
  if (isProtected && !password) {
    return (
      <PasswordPrompt
        handleSubmit={(values) => {
          setPassword(values.password);
          loadData();
        }}
      />
    );
  }

  return (
    <Fragment>
      <ErrorHandler statusCode={errorCode} />
      {isLoading || !image
        ? <Loading message="Loading image..." />
        : (
          <Switch>
            <Route exact path={`${match.path}/edit`} component={Edit} />
            <Route exact path={`${match.path}`} component={View} />
          </Switch>
        )}
    </Fragment>
  );
};

GalleryImage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    params: PropTypes.shape({
      imageId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

GalleryImage.hydrate = (dispatch, params) => dispatch(fetchImage(params.imageId));

export default GalleryImage;
